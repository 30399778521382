<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            lg="6"
            md="6"
            sm="12"
            class="mb-1 mb-md-0"
          >
            <b-row>
              <b-col
                lg="6"
                md="6"
                sm="12"
              >
                <label>Show</label>
                <v-select
                  v-model="per_page"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="per_pageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>
              <b-col
                lg="6"
                md="6"
                sm="12"
              >
                <b-button
                  class="table-header-btn"
                  variant="primary"
                  :to="{ name: 'register-content-upload', params: { type: 'Sound-Recording Rights'} }"
                >
                  Upload new Content
                </b-button>
              </b-col>
            </b-row>
          </b-col>

          <!-- Search -->
          <b-col
            lg="6"
            md="6"
            sm="12"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refSoundRecordList"
        class="position-relative font-weight-bolder"
        :items="fetchSoundRecords"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`${resolveContentVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Uploaded On -->
        <template #cell(uploaded_on)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{
              moment(data.item.created_at).format("dddd, MMMM Do YYYY")
            }}</span>
          </div>
        </template>

        <!-- Actions -->
        <template #cell(actions)="data">
          <div
            v-if="data.item.status !== 'Approved'"
            class="text-nowrap"
          >
            <b-button
              size="sm"
              variant="secondary"
              :to="{ name: 'content-edit', params: { id: data.item.id } }"
            >
              Edit
            </b-button>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="current_page"
              :total-rows="totalRecords"
              :per-page="per_page"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  VBToggle,
  BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import useSoundRecordList from './useSoundRecordList'
import soundRecordsStoreModule from './soundRecordsStoreModule'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BBadge,

    vSelect,
  },
  setup() {
    const SOUND_CONTENT_STORE_MODULE = 'content'
    // Register module
    if (!store.hasModule(SOUND_CONTENT_STORE_MODULE)) { store.registerModule(SOUND_CONTENT_STORE_MODULE, soundRecordsStoreModule) }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SOUND_CONTENT_STORE_MODULE)) store.unregisterModule(SOUND_CONTENT_STORE_MODULE)
    })

    const isAddNewArticleSidebarActive = ref(false)

    const getContentRoles = content => {
      console.log(content)
    }

    const {
      fetchSoundRecords,
      tableColumns,
      per_page,
      current_page,
      totalRecords,
      dataMeta,
      per_pageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSoundRecordList,
      refetchData,
      resolveContentVariant,
    } = useSoundRecordList()

    return {
      // Sidebar
      isAddNewArticleSidebarActive,

      fetchSoundRecords,
      tableColumns,
      per_page,
      current_page,
      totalRecords,
      dataMeta,
      per_pageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSoundRecordList,
      refetchData,

      resolveContentVariant,

      getContentRoles,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.table-header-btn {
  margin-right: 2px;
}
@media only screen and (max-width: 992px) {
  .table-header-btn {
    margin-top: 3px;
    margin-right: 0;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
